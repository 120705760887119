.main-header-ctn {
  background: var(--ion-header-background) !important;
  // min-height: 94px;
  //margin-top: 0% !important;
  //padding: 0px;
  // margin: 0% 6% 0% 12%;
  //padding-left: 4%;
  // padding-top: 20px;
  border-radius: 0px;
  .header-row {
    max-width: 1254px !important;
    display: flex !important;
    justify-content: space-between !important;
    height: 95px;
  }
  .branding-ctn {
    width:40%;
    .MenuIcon {
      display: none;
    }
    padding-left: 20px;
    display: flex;
    cursor: pointer;
    .domain-ctn {
      display: flex;
      flex-direction: row !important;
      align-items: center;
      svg {
        color: #fff;
      }
      .site-title {
        .site-logo {
          width: var(--logo-xl-width);
          height: var(--logo-xl-height);
        }
      }
    }
  }

  .h-btns {
    font-size: 18px;
    font-weight: 600;
    color: var(--ion-color-primary-contrast);
    letter-spacing: 0.02em;
  }

  .live-clock {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    color: var(--ion-color-primary);
    font-family: var(--font-family);
    .date {
      color: var(--ion-color-primary);
      font-size: 14px;
    }
    .time {
      font-size: 15px;
      font-weight: bold;
      color: var(--ion-color-primary);
      margin-left: 8px;
    }
    .clock-timezone {
      display: flex;
      color: var(--ion-color-primary);
      margin-left: 7px;
      font-size: 14px;
      position: relative;
      span {
        color: var(--ion-icon-color);
        cursor: pointer;
        font-size: 14px;
        padding: 0px 3px;
      }
      .dropdown {
        min-width: 250px;
        top: 14px;
        left: -78px;
        position: absolute;
        width: 160%;
        z-index: 14;
        background: var(--ion-text-light);
        padding: 0;
        border-radius: 7px;
        border: 1px solid #dddddd;
        font-weight: normal;
        list-style: none;
        visibility: hidden;
        li {
          list-style-type: none;
          padding-left: 0;
          margin: 0;
          color: #000;
          border-radius: 7px 7px 0 0;
          display: block;
          padding: 10px;
          text-decoration: none;
          border-bottom: 1px solid #dddddd;
          box-shadow: inset 0 1px 0 var(--ion-text-light);
          cursor: pointer;
          background: transparent;
        }
        li:hover {
          background: #f3f8f8;
        }
      }
      .dropdown::before {
        margin-right: -7px;
        border-width: 0 8px 8px 8px;
        border-color: var(--ion-text-light) fff transparent;
        content: '';
        height: 0;
        position: absolute;
        bottom: 100%;
        right: 50%;
        border-style: solid;
      }
      .active {
        visibility: visible !important;
      }
    }
  }

  .header-actions {
    width: 60%;
    padding-right: 15px;
    .header-right-actions {
      width: 100%;
      display: flex !important;
      flex-direction: row;
      justify-content: space-between !important;
      font-family: var(--font-family);
      cursor: pointer;
      .home-search-filter {
        .select-search {
          margin-top: 2px !important;
        }
        input {
          width: 380px;
          max-width: 290px;
          font-family: var(--font-family);
        }
      }
      .user-profile {
        font-size: 14px;
        color: var(--ion-color-primary);
        display: flex;
        flex-direction: column;
        padding: 0px 4px !important;
        font-family: var(--font-family);
        font-weight: 500;
        .nav-link {
          text-decoration: none;
          color: var(--ion-color-primary);
          font-weight: 500;
        }
        span {
          color: var(--ion-color-primary);
          padding-top: 3px;
          font-weight: 500;
        }
        .text-date {
          font-size: 14px !important;
          font-weight: 500;
        }
      }
      .profile-section {
        display: flex;
        margin: 0;
        padding: 0px !important;
        justify-content: flex-end;
        .accountIcon {
          height: 13px;
          margin-right: 2px;
        }
      }
      .h-list-item {
        min-width: 170px;
        padding: 4px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        font-family: var(--font-family) !important;
        .my-account {
          padding: 0px 4px;
          color: var(--ion-icon-color);
          font-size: 14px;
          .MuiButton-startIcon {
            margin-right: 0px;
          }
        }
        .logout-btn {
          font-size: 14px;

          .MuiButton-startIcon {
            margin-right: 2px;
            .fa-sign-out-alt {
              font-size: 16px;
            }
          }
        }

        .text:hover {
          text-decoration: underline !important;
        }
        div,
        span {
          color: var(--ion-icon-color);
          font-family: var(--font-family) !important;
        }
      }
    }
  }
}

.user-popover-list {
  background-color: var(--ion-header-background);
  .MuiList-padding {
    padding: 0px !important;
  }
  .nav-link {
    color: transparent !important;
    font-family: var(--font-family) !important;
  }

  .ion-list-item {
    color: #1e1e1e !important;
    cursor: pointer;
  }

  .user-acc-section {
    display: flex;
    justify-content: space-between;
    width: 100%;
    // border-bottom: 1px solid #7e97a7;

    font-family: var(--font-family) !important;

    .label {
      color: #3b5160;
      font-size: 13px;
      font-weight: bold;
      font-family: var(--font-family) !important;
    }
  }

  .logout-section {
    display: flex;
    justify-content: center;
    font-family: var(--font-family) !important;
    .MuiListItem-root {
      border-bottom: 1px solid #e0e6e6;
    }
  }
}

@media screen and (max-width: 720px) {
  .main-header-ctn {
    display: none;
  }
}
