/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /* Gradient styles */
  --gradient-color-primary: #fab841;
  --ion-text-white:#fff;
  --gradient-color-secondary: #ffc609;
  --premium-odd-background: #72e3a0;

  /** primary **/
  --ion-primary-gradient: linear-gradient(
    98.85deg,
    #0f2327 3.56%,
    #0f2327 97.13%
  );
  --ion-mobile-gradient-active: linear-gradient(
    98.85deg,
    #0f2327 3.56%,
    #0f2327 97.13%
  );
  --ion-mobile-gradient: linear-gradient(180deg, #22394b 0%, #1a2831 100%);

  --ion-header-gradient: linear-gradient(
    98.85deg,
    #0f2327 3.56%,
    #0f2327 97.13%
  );

  --ion-subheader-gradient: linear-gradient(98.85deg, #fff 3.56%, #fff 97.13%);
  --ion-button-gradient: linear-gradient(
    98.85deg,
    #f02423 3.56%,
    #c61c03 97.13%
  );
  --ion-heading-color: #1f5058;
  --ion-color-link: #0f2327;
  --button: #183f45;
  --ion-color-primary: #fff;
  --ion-color-primary-active: #d9534f;
  --ion-secondary-color: #8c1000;
  --ion-color-primary-contrast: #fff;
  --ion-color-primary-contrast-rgb: 10, 13, 21;
  --ion-color-primary-shade: #fada83;
  --ion-color-primary-tint: #d1aa3d;
  --ion-inplay-color: #2aa033;
  /* --ion-color-primary-shade: #D84D94;
  --ion-color-primary-tint: #8A2459; */

  /* Home page */
  --home-page-img-bg-color: #202638;

  /** secondary **/
  --ion-color-secondary: #37446e;
  --ion-color-secondary-rgb: 55, 68, 110;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #303c61;
  --ion-color-secondary-tint: #4b577d;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #34a300;
  --ion-color-success-rgb: 52, 163, 0;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #2e8f00;
  --ion-color-success-tint: #48ac1a;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #ff6262;
  --ion-color-danger-rgb: 255, 98, 98;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e05656;
  --ion-color-danger-tint: #ff7272;
  --ion-color-lost: #cf0a13; /* #FF8383 */

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #cbd4f0;
  --ion-color-light-tint: #f5f6f9;

  /** Sports List Navbar Colors **/
  --ion-nav-card-background: #183f45;
  --ion-nav-card-text: #fff;

  /* Accordians */
  --ion-accordian-header-bg: #243a48;

  /** Header Colors **/
  --ion-header-text-color: #828db9;
  --ion-header-text-color-rgb: 130, 141, 185;
  --ion-header-background: #0f2327;
  --ion-header-link-color: rgba(255, 255, 255, 0.6);

  /** Back and Lay **/
  --back-odd-background: #A7D8FD;
  --back-odd-background-light: #66a0c6;
  --back-odd-background-dark: #0988dc;
  --back-odd-background-rgb: 151, 211, 255;
  --lay-odd-background: #f9c9d4;
  --lay-odd-background-rgb: 255, 173, 173;
  --lay-odd-background-light: #d0929f;
  --lay-odd-background-dark: #f25c7e;

  /** Table colour **/
  --table-headers-light: #fff;
  --headers-font-family: var(--font-family);
  --table-header-background: #ced5da;
  --table-body-background: #ffffff;
  --table-border-color: rgba(15, 35, 39, 0.2);

  /* Odds info icons */
  --market-info-icons: var(--ion-color-primary);

  /*
   * White Label variables
   * ---------------------
   */
  --odd-btn-animation-color:#fdc775;
  --footer-text-color: #6a7394;
  --nav-list-live-icon-color: #ff0000;

  /* Home page */
  --homepage-bg-color: #202638;

  /* offers section */
  --light-text-color: #8792b3;

  /** Header Logo Sizes **/
  --logo-xl-width: 155px;
  --logo-xl-height: 53px;
  --logo-lg-width: 150px;
  --logo-lg-height: 55px;
  --logo-md-width: 64px;
  --logo-md-height: 26px;
  --logo-sm-width: 94px;
  --logo-sm-height: 46px;
  --logo-xs-width: 74px;
  --logo-xs-height: 46px;
  --font-family: Lato, Avenir, Verdana, Nunito;
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  :root {
    --font-family: Lato, sans-serif;
  }
}
/*
 * Light Colors
 * ------------------
 */
.ios body,
.md body {
  --scorecard-bg:#fff;
  --scorecard-text:#000;
  --home-page-bg: url('../assets/images/home/bg.jpg');
  --home-page-bg-mob: url('../assets/images/home/mobilebg.jpg');
  --super-casino-lobby-bg: #0f2327;
  --super-casino-tab-container-bg: var(--super-casino-lobby-bg);
  --super-casino-tab-bg: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    rgba(255, 255, 255, 0.1);
  --super-casino-tab-border: #879193;

  --ion-background: #f5f5f5;
  --ion-background-color-rgb: 0, 20, 61;
  --ion-background-color: #f5f5f5;
  --ion-primary: #0f2327;
  --ion-background-secondary: #eeeeee;
  --ion-background-tertiary: #f7f7f7;

  --linear-gradient-background-1: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 86%
  );
  --linear-gradient-background-2: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 117.02%
  );

  --webkit-gradient-background-1: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 86%
  );
  --webkit-gradient-background-2: linear-gradient(
    88.06deg,
    var(--ion-background) 30%,
    var(--ion-color-primary) 117.02%
  );
  --ion-icon-color: #ffecc6;
  --ion-text-color: #000e;
  --ion-text-color-rgb: 0, 20, 61;
  --ion-text-light: #ffffff;
  --ion-text-light-secondary: #c5c5c5;
  --ion-text-light-rgb: 255, 255, 255;
  --ion-text-dark: #0a0d15;
  --ion-text-dark-rgb: 10, 13, 21;
  --ion-text-color-contrast: var(--ion-text-dark);
  --ion-text-color-contrast-rgb: var(--ion-text-dark-rgb);

  --ion-font-family: var(--font-family);
  --ion-default-font: Tahoma, Helvetica, sans-serif;

  /** Card Variables**/
  --ion-card-background: #fff;
  --ion-card-text-color: #00143d;
  --ion-card-border: 1px #ddd;
  --ion-card-border-color-rgb: 10, 13, 21;

  /** Toolbar Variables**/
  --ion-toolbar-background: none;
  --ion-item-background: none;
  --ion-item-background-activated: none;

  /** Popover Variables**/
  --ion-popover-background: #fff;
  --ion-popover--hover-background: #fff;

  /** Expansion Panel Variables**/
  --accordion-summary-root-background: #ffffff;
  --accordion-summary-root-expanded-background: #202d57;
  --accordion-summary-root-text-color: var(--ion-text-color);
  --accordion-details-root-background: #fff;

  /** All Markets variables **/
  --am-accordion-summary-root-background: #fff;
  --am-accordion-summary-root-expanded-background: var(--ion-color-primary);
  --am-accordion-summary-root-text-color: var(--ion-text-color);
  --am-accordion-details-root-background: #ffffff;

  /** IonSelect Styles **/
  --select-element-background: var(--ion-popover--hover-background);
  --select-popover-background: var(--ion-background);
  --select-popover-active-background: var(--ion-color-primary);

  /** Dialog Styles **/
  --dialog-header-background: #202d57;
  --dialog-content-background: var(--ion-background);

  /** DatePicker-Container Styles **/
  --date-picker-container-background: #fff;
  --date-picker-header-button-background: #fff;

  /** Drawer Styles **/
  --drawer-background: #fff;
  --drawer-background-contrast: #ffffff;
  --drawer-text-color: #00143d;

  /** Input Field Styles **/
  --input-background-color: #ffffff;
  --input-text-color: #1f2536;
  --input-border-color: #ddd;

  /** Reports style **/
  --reports-bg-color: #fff;
  --reports-title-color: var(--ion-color-primary);
  --filter-controls-border: #ddd;

  /* Left menu section */
  --left-section-bg-color: #fff;

  /* Odds info icons */
  --market-info-icons: var(--ion-color-primary);

  /* Scorecard */
  --score-card-details-color: #fff;

  /* Markets Accordian*/
  --accordian-headers-color: #fff;
  --sports-team-name-color: #005ba2;
}

.ios body {
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
}

/*
   * Material Design Dark Theme
   * -------------------------------------------
   */

.md body {
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
}

ion-title {
  --color: #ffffff;
}

/* Optional CSS, this is added for the flashing that happens when toggling between themes */
ion-item {
  --transition: none;
}
