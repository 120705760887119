.home-page-ctn {
  position: relative;
  .page-banner-ctn {
    .carousel-root .carousel .slider-wrapper .slide img {
      pointer-events: all;
      cursor: pointer;
    }

    .banners-row {
      background-color: black;
    }
  }

  // .banner-images {
  //   height: 250px;
  // }

  .home-grid {
    background: var(--ion-background);
    //padding: 12px 0px;

    padding: 0px !important;
    margin: 0px !important;

    .slick-slider {
      width: 99vw;
      //overflow: hidden;
      .slick-list {
        margin-bottom: 8px;
      }
      .slick-next {
        z-index: 1;
        right: 17px !important;
      }
      .slick-prev {
        z-index: 1;
        left: 10px !important;
      }
    }
    // .slick-next {
    //   position: static;
    //   background-color: none;
    //   //margin-top: -132px;
    // }
    // .slick-next:before {
    //   color: rgb(29, 27, 27) !important;
    // }

    // .slick-next:before {
    //   font-family: 'slick';
    //   //font-size: 25px;
    //   background-color: var(--ion-text-light);
    //   line-height: 0;
    //   opacity: 1 !important;
    //   color: #000;
    //   padding: 4px;
    // }
    .live-games,
    .sports {
      .live-games-header,
      .sports-header {
        display: inline-flex;
        justify-content: space-between;
        align-items: baseline;

        .live-games-text,
        .sports-text,
        .more-link {
          margin: 20px 0;
          display: flex;
          align-items: center;
          color: var(--ion-text-light);
          font-size: 16px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          font-weight: 800;
          opacity: 0.8;
          text-decoration: none;
        }

        .more-link {
          color: var(--ion-color-primary-shade);
        }
      }
    }

    //.live-games,
    .sports-book {
      display: flex;
      //justify-content: center;
      // padding-bottom: 52px;

      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin: 15px 0 8px;
      .sport {
        padding: 0px !important;
        margin: 0px !important;
      }
      .sport:nth-child(2n) {
        margin: 0 8px !important;
      }
    }

    .live-games {
      padding: 0px !important;
      margin: 0px !important;
      //width: 264px !important;
      //width: calc(100vh /5) !important;
      width: auto;
      padding-right: 8px !important;
      .grid-images {
        cursor: pointer;
        //overflow: hidden;
        width: 100%;
        height: 240px;
        @media screen and (max-width:1440px){
          height: 174px;
        }
        @media screen and (max-width:1366px){
          height: 165px;
        }
      }
    }

    .grid-name {
      // background-color: var(--gradient-color-primary);
      font-size: 22px;
      line-height: 1;
      color: #420a57;
      background: #fab841;
      width: 100%;
      padding: 17px 4px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-family:var(--font-family);
      font-weight: normal;
    }
  }
}

@media screen and (min-width: 1120px) {
  .home-page-ctn {
    .page-banner-ctn {
      margin: 0%;
    }
    .home-grid {
      // margin: 0% 6% 0% 12%;
      margin: 0px;
    }
  }
}

@media screen and (max-width: 720px) {
  .home-page-ctn {
    padding: 8px;
    //padding-bottom: 15%;
    .Popular-header {
      // background: #ce5e39;
      // border-bottom: 1px solid var(--ion-inplay-color);
      // width: 100%;
      // color: var(--ion-color-primary);
      // padding: 4px;
      // margin-bottom: 10px;
      // font-weight: 18px;
      // text-transform: capitalize;

      display: flex;
      align-items: center;
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
      position: relative;
      padding: 8px;
      color: var(--ion-color-primary);
      background: #cc5f36;
      font-size: 18px;
      font-weight: 700;
      .popularIcon {
        font-size: 22px;
        height: 27px;
        width: 27px;
      }
      .title {
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 5px;
        color: var(--ion-color-primary);
      }
    }
    .banners-row {
      //height: 90px;
      background-color: var(--ion-text-light) !important;
      border-radius: 2px;
      margin-bottom: 8px;
      .top-banner {
        display: block;
        height: 77px;
        position: relative;
        border-radius: 2px;
      }
      .bottom-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 77px;
        position: relative;
        border-radius: 2px;
        background: url('../images/banners/live-tables.png') center/auto 100%
          no-repeat #b40022;
      }

      .banner-images {
        height: 100%;
        object-fit: cover;
      }
    }
    .top-bottom-banner {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 77px;
      position: relative;
      border-radius: 2px;
      background: url('../images/home/horse-racing1.png') center/auto 100%
        no-repeat #607030;
    }

    .img-btn {
      display: none;
    }

    .web-banners {
      display: none !important;
    }

    .home-grid {
      padding: 4px 0px;
      .sports-book {
        .sport {
          padding: 0px 0px 4px 0px;
        }
      }
      .mob-view {
        .mob-casino-row {
          .mob-casino-col {
            padding: 2px;
          }
        }
      }
    }

    .more-link {
      margin: 10px 0 !important;
      padding: 0;
      font-size: 14px !important;
      font-weight: 600;
      line-height: 15px;
      ion-icon {
        margin-bottom: -2px;
      }
    }

    .live-games-text,
    .sports-text {
      margin: 0px !important;
      padding-left: 0px;
      font-size: 12px !important;
      line-height: 14px;
    }

    .live-games,
    .sports {
      .live-games-header,
      .sports-header {
        padding: 0px;
      }
    }
  }
}
