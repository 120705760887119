// Components
@import './table.scss';
@import './card.scss';
@import './buttons.scss';
@import './select.scss';
@import './datepicker.scss';
@import './dialog.scss';
@import './popover.scss';
@import './drawer.scss';
@import './tooltip.scss';
@import './accordion.scss';
@import './input.scss';
@import './reports.scss';
@import './loadingCircle';
@import './search_filter.scss';
@import './header.scss';
@import './scrollbar.scss';
@import './oddsTable.scss';
@import './tabs.scss';

// Pages
@import './Home.scss';

//@import url('https://fonts.googleapis.com/css2?family=Tahoma&display=swap');

/* Backdrop styles */
// .MuiBackdrop-root {
//   background: rgba(72, 99, 155, 0.85) !important;
// }

.hand-pt {
  cursor: pointer;
}

.err-box {
  background: rgba(255, 87, 87, 0.2);
  border: 0.5px solid #ff5757;
  border-radius: 12px;
  padding: 4px;
  color: var(--text1);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .closeIcon {
    cursor: pointer;
  }
  .msg {
    width: 90%;
    white-space: break-spaces;
    padding: 4px;
  }
}
.success-box {
  background: rgba(84, 201, 117, 0.2);
  border: 0.5px solid #54c975;
  border-radius: 12px;
  padding: 4px;
  color: var(--text1);
  font-size: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .closeIcon {
    cursor: pointer;
  }
  .select-text {
    font-size: 13px !important;
  }
  textarea:focus,
  input:focus {
    outline: none !important;
  }
  input:focus {
    outline: none !important;
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .msg {
    width: 90%;
    white-space: break-spaces;
    padding: 4px;
  }
}

/* Material UI Tabs */
.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 2px solid var(--ion-color-primary);
}
.MuiTab-root {
  text-transform: none;
}
.MuiTabs-indicator {
  display: none;
}

/* Punter reports styles */
.admin-module-ctn {
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
}

/* Recaptcha styles */
.recaptcha {
  display: none;
}

.sticky-col {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 1px;
}

.b-text {
  font-weight: 600 !important;
}

.tiny-info-text {
  font-size: 12px;
}

.d-flex-column {
  display: flex !important;
  flex-direction: column;
}

.left-align {
  align-items: left !important;
}

.m-link {
  cursor: pointer !important;
}

.profit {
  color: var(--ion-color-success) !important;
}

.loss {
  color: var(--ion-color-danger) !important;
}

.back-bet,
.back-odd {
  background: var(--back-odd-background);
}
.lay-bet,
.lay-odd {
  background: var(--lay-odd-background);
}

.back-bet-font {
  color: var(--back-odd-background-dark);
}
.lay-bet-font {
  color: var(--lay-odd-background-dark);
}

// index based color
.back-bet,
.back-odd0 {
  background: #72bbef;
}
.back-bet,
.back-odd1 {
  background: #b7d5eb;
}
.back-bet,
.back-odd2 {
  background: #d7e8f4;
}

.lay-bet,
.lay-odd0 {
  background: #faa9ba;
}

.lay-bet,
.lay-odd1 {
  background: #efd3d9;
}

.lay-bet,
.lay-odd2 {
  background: #f6e6ea;
}

// Carousel styles
.carousel .slide {
  background: none !important;
}

.primary-b-text {
  font-family: var(--font-family);
  font-weight: 600;
  color: var(--ion-color-primary-tint);
}

.display-none {
  display: none;
}

@keyframes color-change {
  0% {
    color: red;
  }
  50% {
    color: var(--ion-color-success);
  }
  100% {
    color: var(--ion-color-primary);
  }
}

@keyframes flashing-text {
  0% {
    color: red;
    transform: scale(1.2);
  }
  25% {
    color: var(--ion-color-success);
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  75% {
    color: red;
    transform: scale(1);
  }
  100% {
    color: var(--ion-color-success);
    transform: scale(1.2);
  }
}

@media screen and (max-width: 720px) {
  .web-view {
    display: none !important;
  }
}

@media (min-width: 721px) {
  .mob-view {
    display: none !important;
  }
}

@media (max-width: 1200px) {
  .tab-view {
    display: none !important;
  }

  .odds-web-view {
    display: none;
  }
}

@media (min-width: 1200px) {
  .lap-view {
    display: none !important;
  }

  .odds-mob-view {
    display: none;
  }
}

.font-roboto {
  font-family: var(--font-family);
}

span,
div {
  color: var(--ion-text-color);
  font-family: var(--font-family) !important;
}

html,
body {
  height: 100%;
  font-family: var(--font-family);
  --ion-default-font: Tahoma, Helvetica, sans-serif;
  color: #1e1e1e;
  font-size: 12px;
  line-height: 15px;
}

// .ion-button {
//   width: 100%;
//   --background: #183f45 !important;
//   color: var(--ion-color-primary);
// }
// .sc-ion-popover-ios-h {
//   --width: 160px !important;
//   --backdrop-opacity: var(--ion-backdrop-opacity, 0) !important;
// }
.sc-ion-popover-md-h {
  --width: 175px !important;
  --padding: 0px;
  font-size: 11px;
  .item {
    --padding: 0px;
    margin: 0px;
  }
  .item .sc-ion-label-md-h {
    --padding: 0px;
    font-size: 11px;
  }
}
.list-md {
  --padding-top: 12px;
}

.competitions-list-ctn {
  .competitions-title {
    font-size: 18px !important;
    padding: 5px !important;
    text-transform: capitalize;
  }
  .mobile-event-list {
    margin-left: 2%;
    margin-right: 2%;
    margin-top: 10px;
    padding: 0px !important;
    width: 96%;
    box-shadow: none;
    .date-header {
      background-color: var(--ion-header-background) !important;
      color: #fff !important;
      margin-top: 5px;
      height: 30px;
      .time,
      .MuiListItemText-root {
        font-size: 16px;
        color: #fff !important;
        font-family: var(--font-family) !important;
      }
    }
    .competition-name {
      display: flex;
      justify-content: space-between;
    }
    position: relative;
    box-shadow: none;
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    margin-bottom: 10px;
    background-color: none !important;
    .MuiListItem-button {
      border-bottom: 2px solid #ddd;
      background-color: #fff;
      padding: 7px !important;
    }
    .all-markets-nav-link {
      text-decoration: none;
      .MuiListItemIcon-root {
        min-width: 5px !important;
      }
      .MuiListItemText-root {
        font-size: 16px;
        display: flex;
      }
      width: 100%;
      .MuiBadge-root {
        font-size: 16px;
        // margin-top: -8px !important;
      }
    }
  }
}

.MuiButton-root {
  background-color: #1f5058;
  .MuiButton-label {
    color: var(--ion-color-primary);
  }
}
.ion-button {
  --background: #1f5058;
}
input.checkbox {
  width: 18px !important;
}
.MuiTypography-body1,
.MuiTableCell-root {
  font-family: var(--font-family) !important;
}
.focusframe {
  :focus {
    outline: none !important;
    box-shadow: none !important;
  }
}

.MuiTypography-body1 {
  font-family: var(--font-family) !important;
}
.MuiAppBar-colorPrimary {
  box-shadow: none !important;
}
.myb-btn-prev,
.myb-btn-next,
.button-native {
  color: #fff !important;
  --background: #1f5058;
}
.sr-bb .srt-logo-powered-by-dark {
  display: inline-block;
}
.sr-bb .sr-lmt-plus-scb__mid {
  height: 72px !important;
  margin-top: 20px !important;
  font-size: 10px;
}
.sr-bb .sr-lmt-plus-scb__s-cap {
  font-size: 10px;
}
.sr-bb .sr-lmt-plus-scb__clock {
  font-size: 10px;
}
.sr-bb .sr-lmt-plus-scb__status-sep {
  padding: 0px 4px;
  font-size: 10px;
}
// ion-content {
//   // overwrite inline styles
//   --offset-bottom: auto!important;
//   --overflow: hidden;
//   overflow: auto;
//   &::-webkit-scrollbar {
//     display: none;
//   }
// }
.newlacunch-menu {
  transition: 0.3s ease-in;
  -webkit-animation: newlaunch 1s;
  -moz-animation: newlaunch 1s;
  animation: newlaunch 1s;
  animation-iteration-count: infinite;
}


@keyframes newlaunch {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: red !important;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    color: #f3bd42 !important;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: red !important;
  }
}
@-moz-keyframes newlaunch {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: red !important;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    color: #f3bd42 !important;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: red !important;
  }
}
@-webkit-keyframes newlaunch {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: red !important;
  }
  50% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    color: red !important;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    color: #f3bd42 !important;
  }
}

ion-popover [popover]:not(:popover-open):not(dialog[open]) {
  display: contents;
}