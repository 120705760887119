.card11 {
  background-color: #ddd;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  .icon-ctn {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center !important;
    background-color: var(--theme2-bg);
    height: 40px;
    width: 40px;
    border-radius: 6px;
    margin-left: 10px;
    margin-right: 10px;
    @media screen and (max-width: 720px) {
      height: 32px;
      width: 32px;
    }
    .icon {
      fill: var(--ion-color-primary);
      color: var(--ion-color-primary);
      font-size: 22px;
    }
  }
  .text1 {
    color: var(--text1);
    font-size: 13px;
  }
}
.notificatio-ctn {
  background-color: var(--ion-background);
  border-radius: 16px;
  margin: 10px;
  .title {
    background-color: var(--card-bg);
    display: flex;
    justify-content: center !important;
    align-items: center;
    height: 45px;
    margin-top: 4px;
    border-radius: 16px;
    .title-name {
      width: 100%;
      text-transform: capitalize;
      font-size: 16px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: var(--teble-head);
      font-weight: bold;
    }

    .btn-ctn {
      position: absolute;
      right: 8px;
      .save-btn {
        color: var(--text);
        font-weight: bold;
        background-color: var(--ion-color-primary);
        text-transform: capitalize !important;
        font-size: 14px;
        border-radius: 100px;
        .icon {
          margin-right: 8px;
          color: var(--text);
          fill: var(--text);
        }
      }
    }
  }
  .MuiButton-containedPrimary,
  .submit-btn {
    color: var(--text1);
    font-weight: bold;
    background-color: var(--ion-color-primary);
    text-transform: capitalize !important;
    font-size: 14px;
    border-radius: 83.0435px;
    width: 100%;
    .icon {
      margin-right: 8px;
      color: var(--text1);
      fill: var(--text1);
    }
  }
  .MuiTableContainer-root {
    overflow: hidden !important;
  }
  .MuiPaper-root {
    margin-top: 0px;
    .MuiTable-root {
      .MuiTableRow-root {
        .MuiTableCell-root {
          padding: 10px;
          border-bottom: 1px solid var(--card-bg);
          &.MuiTableCell-root:first-child {
            border-right: 1px solid var(--card-bg);
            background: var(--bg3) !important;
            width: 25%;
            font-weight: 800;
            font-family: var(--font-family);
            text-transform: uppercase;
            color: var(--text-primary);
          }
          &.MuiTableCell-root {
            font-family: var(--font-family);
            .my-helper-text,
            .MuiFormHelperText-root {
              color: #f44336;
              position: absolute;
              right: 19px;
              margin-top: -20px;
              z-index: 1000;
            }
            font-weight: bold;

            background-color: var(--ion-background) !important;
            input[type='number']::-webkit-inner-spin-button,
            input[type='number']::-webkit-outer-spin-button {
              -webkit-appearance: none;
              -moz-appearance: none;
              appearance: none;
              background-color: var(--teble-head) !important;
              font-weight: bolder;
              font-family: var(--font-family);
            }
            .input-design,
            .native-input[disabled].sc-ion-input-md {
              color: var(--text1) !important;
              background-color: var(--teble-head) !important;
              //border: 0px !important;
              font-weight: bolder;
            }
            .input-design,
            .native-input.sc-ion-input-md,
            .native-input.sc-ion-input-ios {
              color: var(--text1) !important;
               background-color: var(--teble-head) !important;
              //border: 0px !important;
              height: 30px;
              width: 50%;
              font-weight: bolder;
            }
            .MuiOutlinedInput-input {
              background-color: var(--disabled-bg) !important;
              border-radius: 100px !important;
              color: var(--text1) !important;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
